import React, { useState } from "react";
import WigLoader from "@accrosoft-ltd/accropress-websites-components/dist/esm/wigLoader";
import Layout from "@accrosoft-ltd/vf-careers-site-theme/src/components/Layout";

import SmartFeedList from "@accrosoft-ltd/vf-careers-site-theme/src/components/SmartFeedList";
import SmartFeedFilters from "@accrosoft-ltd/vf-careers-site-theme/src/components/SmartFeedFilters";
import SmartFeedNavigation from "@accrosoft-ltd/vf-careers-site-theme/src/components/SmartFeedNavigation";
import SmartFeedJobAlerts from "@accrosoft-ltd/vf-careers-site-theme/src/components/SmartFeedJobAlerts";

import { useFiltersContext } from "@accrosoft-ltd/vf-careers-site-theme/src/context/smartfeed/FiltersContext";
import { useCookies } from "react-cookie";
import {
  trackAnalyticsEvent,
  trackAnalyticsPageView,
  useStateWithSessionStorage,
} from "@accrosoft-ltd/vf-careers-site-theme/src/components/analytics";
import { useTracking } from "react-tracking";

import "react-multi-carousel/lib/styles.css";

import Slider from "react-slick";
import { useEffect } from "react";

export default function HomepagePage_Content(props) {
  useEffect(() => {
    if (props.location && props.location.hash) {
      const anchor = document.querySelector(`${props.location.hash}`);

      if (!anchor) return;

      const item = window.pageYOffset + anchor.getBoundingClientRect().top;

      window.scrollTo({
        top: item,
        behavior: "smooth",
      });
    }
  });

  const {
    filtersState: { filters },
    onSelectionChange,
  } = useFiltersContext();

  const {
    pageContext: { config, contentMetaData, contentDataData },
  } = props;

  const [cookies, setCookie] = useCookies(["ap-signals-user-id"]);
  const [sessionIDValue, setSessionIDValue] = useStateWithSessionStorage(
    "ap-signals-session-id"
  );

  const { trackEvent } = useTracking(
    {
      page: "Homepage_Content",
      location: props.location,
      apiKey: props.apiKey,
    },
    {
      dispatch: (data) =>
        trackAnalyticsEvent(
          data,
          cookies["ap-signals-user-id"],
          sessionIDValue,
          siteConfig
        ),
    }
  );

  const siteConfig = config;

  const [vacancyLoading, setVacancyLoading] = useState(true);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    adaptiveHeight: true,
    centerPadding: "40px",
    autoplay: true,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 180,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 900 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1080, min: 700 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 1,
    },
  };

  let internalView = false;
  let iFrameView = false;
  let HideCareersLink = false;

  if (props.urlLocation && props.urlLocation.search) {
    let searchParams = new URLSearchParams(props.urlLocation.search);
    let entries = searchParams.entries();

    for (let pair of entries) {
      if (pair[0] === "iframe") {
        iFrameView = pair[1];
      }
      if (pair[0] === "HideCareersLink") {
        HideCareersLink = pair[1];
      }
      if (pair[0] === "IsIntranet") {
        internalView = pair[1];
      }
    }
  }

  const onNavChange = (value) => {
    var temp = {
      target: {
        name: "postingtype",
        value,
      },
    };
    onSelectionChange(temp);
  };

  return (
    <Layout
      siteConfig={siteConfig}
      location={props.location}
      apiKey={props.apiKey}
      groupOrIdParam={props.groupOrIdParam}
      smartfeedCompanyData={props.smartfeedCompanyData}
      appGatewayURL={props.appGatewayURL}
      vacanciesRouteLoading={props.vacanciesRouteLoading}
      vacanciesRouteError={props.vacanciesRouteError}
      trackEvent={props.trackEvent}
      title={`${
        siteConfig.CompanyName || props.smartfeedCompanyData.CompanyName
      } Careers`}
      description={`Careers at ${
        siteConfig.CompanyName || props.smartfeedCompanyData.CompanyName
      }`}
      useGroupVacsLink={props.useGroupVacsLink}
    >
      {!props.vacanciesRouteLoading &&
      (siteConfig.siteConfig || props.smartfeedCompanyData) &&
      props.apiKey ? (
        <>
          <div
            className="banner"
            style={{
              backgroundRepeat: "no-repeat",
              background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(https://cdn2.accropress.com/1f0a44b0-2da0-47a9-b852-40d2ad27d5e3/media/global/dc5028d2-91ad-4a52-9c7c-b71ccb21a50e_full_homepage.jpg)`,
            }}
          >
            <h1 className="text-center text-white">
              {props.smartfeedCompanyData.CompanyName} Careers
            </h1>
          </div>
          <div className="white-bkgd">
            <div className="container py-3">
              <div className="row">
                <div className="col-12 mb-3">
                  <WigLoader
                    contentDataData={contentDataData}
                    contentMetaData={contentMetaData}
                    contentDataArray="0"
                    contentDataChild="0"
                    AccroPressProdEndpoint={config.AccroPressProdEndpoint}
                    AccroPressLocalEndpoint={config.AccroPressLocalEndpoint}
                    integratorID={config.integratorID}
                    integratorAccessToken={config.integratorAccessToken}
                    imageFormatting={
                      process.env.IMAGE_FORMATTING_SECRET ||
                      process.env.GATSBY_IMAGE_FORMATTING_SECRET
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container py-3">
            <div className="row">
              <div className="col-12">
                <h1>{contentDataData[0].children[1].value}</h1>
                <h3 className="font-italic">
                  {contentDataData[0].children[2].value}
                </h3>
              </div>
              <div className="row benefit-container">
                <div className="desktop d-md-block d-sm-none">
                  <Slider {...settings}>
                    {contentDataData[0].children[3].children.map((cell) => (
                      <div className="card p-0 col-12">
                        <div className="card-header text-center">
                          <img
                            src={cell[0].value}
                            alt={cell[1].value}
                            height={100}
                          />
                        </div>
                        <div className="card-body">
                          <div className="card-title font-weight-bold">
                            {cell[1].value}
                          </div>
                          <div className="card-text">{cell[2].value}</div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
                <div className="mobile d-none d-sm-block d-md-none col-12">
                  {contentDataData[0].children[3].children.map((cell) => (
                    <div className="card p-0 col-12">
                      <div className="row">
                        <div className="card-header text-center col-2 pr-3">
                          <img
                            src={cell[0].value}
                            alt={cell[1].value}
                            height={100}
                          />
                        </div>
                        <div className="card-body col-10">
                          <div className="card-title font-weight-bold">
                            {cell[1].value}
                          </div>
                          <div className="card-text">{cell[2].value}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="row homePageVacancies">
              <div className="col-12 mb-3">
                <SmartFeedNavigation
                  active={filters?.postingtype || "Careers"}
                  onNavChange={onNavChange}
                />
              </div>
            </div>

            {filters?.postingtype !== "Alerts" ? (
              <>
                <div className="row">
                  <div className="col-12">
                    {siteConfig.showAdvancedFilters && (
                      <SmartFeedFilters
                        siteConfig={siteConfig}
                        apiKey={siteConfig.apiKey || props.apiKey}
                        group={
                          siteConfig.group
                            ? siteConfig.group
                            : props.groupOrIdParam === "id"
                            ? false
                            : props.groupOrIdParam === "group"
                            ? true
                            : false
                        }
                        groupOrIdParam={props.groupOrIdParam}
                      />
                    )}
                  </div>
                  <div className="col-12">
                    <SmartFeedList
                      siteConfig={siteConfig}
                      apiKey={siteConfig.apiKey || props.apiKey}
                      group={
                        siteConfig.group
                          ? siteConfig.group
                          : props.groupOrIdParam === "id"
                          ? false
                          : props.groupOrIdParam === "group"
                          ? true
                          : false
                      }
                      smartfeedCompanyData={
                        siteConfig.smartfeedCompanyData ||
                        props.smartfeedCompanyData
                      }
                      trackEvent={props.trackEvent}
                      useGroupVacsLink={props.useGroupVacsLink}
                      vacancyLoading={vacancyLoading}
                      setVacancyLoading={setVacancyLoading}
                    />
                  </div>
                </div>
              </>
            ) : (
              <div className="row justify-content-center">
                <div className="col-12 col-lg-8">
                  <SmartFeedJobAlerts
                    siteConfig={siteConfig}
                    trackEvent={trackEvent}
                    apiKey={props.apiKey}
                    gdprKey={props.gdprKey}
                    group={props.group}
                    CompanyGroupName={props.CompanyGroupName}
                    VacancyPageBaseURL={props.VacancyPageBaseURL}
                    ReturnToSearchURL={props.ReturnToSearchURL}
                    OptInLink={props.OptInLink}
                    UnsubscribeLink={props.UnsubscribeLink}
                    location={siteConfig.showLocationJA}
                    region={siteConfig.showRegionJA}
                    category={siteConfig.showCategoryJA}
                    industry={siteConfig.showIndustryJA}
                    jobType={siteConfig.showJobTypeJA}
                    jobTime={siteConfig.showJobTimeJA}
                  />
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="notfound">
          <section className="app-content">
            <div className="app-content-content">
              <div className="container">
                <div className="loading-panel">
                  <div className="loading-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <span className="loading-text">
                    Please wait... If content is not shown soon then you may
                    have the wrong URL.
                  </span>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </Layout>
  );
}
